import { ContractStatuses } from "kui-crm"
import isContractActive from "../../../../../utils/isContractActive"

export const getTransferTypes = (
  rentalContractStatus?: ContractStatuses | null,
  hasServiceContract?: boolean,
) => {
  if (
    hasServiceContract &&
    (!rentalContractStatus ||
      rentalContractStatus === "Closed" ||
      rentalContractStatus === "Archived")
  )
    return [{ label: "От Maroom к собственнику", value: "maroom_landlord" }]
  if (rentalContractStatus === "Pending")
    return [{ label: "От Maroom к жильцу", value: "maroom_tenant" }]
  if (hasServiceContract && isContractActive(rentalContractStatus))
    return [
      { label: "От жильца к Maroom", value: "tenant_maroom" },
      { label: "От жильца к собственнику", value: "tenant_landlord" },
    ]
  return []
}
