import React from "react"
import {
  getAvailableResourcesFields,
  AvailableResourcesStepFields,
} from "kui-crm_actions"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import { availableResourcesFieldsSettings } from "./content"

const AvailableResourcesStep = () => {
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<AvailableResourcesStepFields>({
    defaultValues: {
      ...formStore.fields,
    },
  })
  const fields = getAvailableResourcesFields(availableResourcesFieldsSettings)

  const handleSubmit = (data: AvailableResourcesStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      title="Выберите доступные ресурсы со счетчиками в объекте"
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <Grid container spacing={2}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={6} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(AvailableResourcesStep)
