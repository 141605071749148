import React from "react"
import { InspectionTypes } from "kui-crm"
import { observer } from "mobx-react"
import { clearIndexStores } from "kui-utils"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import { InspectionFormFields, InspectionFormHandler } from "../../types"
import InitialInspectionForm from "../../variants/InitialInspectionForm"
import InventoryInspectionForm from "../../variants/InventoryInspectionForm"
import TransferInspectionForm from "../../variants/TransferInspectionForm"
import RegularInspectionForm from "../../variants/RegularInspectionForm"
import { InspectionFormProps } from "./types"
import { formFieldsName, formStateName } from "../../../../utils/constants"

const inspectionFormByType = (handleSubmit: InspectionFormHandler) => ({
  initial: <InitialInspectionForm handleSubmit={handleSubmit} />,
  inventory: <InventoryInspectionForm handleSubmit={handleSubmit} />,
  transfer: <TransferInspectionForm handleSubmit={handleSubmit} />,
  regular: <RegularInspectionForm handleSubmit={handleSubmit} />,
})

const InspectionForm = (props: InspectionFormProps) => {
  const { handleCreate } = props
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm

  const handleSubmit = async (
    type: InspectionTypes,
    data: InspectionFormFields,
  ) => {
    const inspectionId = await inspectionsStore.createInspection(type, data)
    if (inspectionId) {
      handleCreate()
      formStore.resetForm()
      clearIndexStores(inspectionsStore.indexDB, [
        formStateName,
        formFieldsName,
      ])
    }
  }

  return inspectionFormByType(handleSubmit)[
    (formStore.fields?.type || "initial") as keyof typeof inspectionFormByType
  ]
}

export default observer(InspectionForm)
