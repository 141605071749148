import React, { useEffect } from "react"
import { MultistepForm } from "kui-crm"
import { observer } from "mobx-react"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import CheckAddressStep from "./CheckAddressStep"
import ApartmentMainInfoStep from "./ApartmentMainInfoStep"
import renderRoomsImagesSteps from "../../common/InspectionImagesStep/imagesSteps/renderSteps"
import FullInfoStep from "./FullInitialInfoStep"
import { InspectionFormProps } from "../../types"
import { InspectionRoomsFields } from "../../common/InspectionImagesStep/types"
import AvailableResourcesStep from "./AvailableResourcesStep"

const InitialInspectionForm = (props: InspectionFormProps) => {
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm

  useEffect(() => {
    inspectionsStore.setCanLoadDB(true)
  }, [])

  return (
    <MultistepForm stepShift={1} form={formStore}>
      <CheckAddressStep />
      <ApartmentMainInfoStep />
      <AvailableResourcesStep />
      {renderRoomsImagesSteps(
        "initial",
        formStore.fields as InspectionRoomsFields,
      )}
      <FullInfoStep {...props} />
    </MultistepForm>
  )
}

export default observer(InitialInspectionForm)
