import React from "react"
import { Caption, Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { getAvailableResourcesFields } from "kui-crm_actions"
import { availableResourcesFieldsSettings } from "../AvailableResourcesStep/content"
import { AvailableResourcesBlockProps } from "./types"

const AvailableResourcesBlock = (props: AvailableResourcesBlockProps) => {
  const { form, disabled } = props
  const fields = getAvailableResourcesFields(availableResourcesFieldsSettings)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Caption size="s" weight={500}>
          Доступные ресурсы
        </Caption>
      </Grid>
      {fields.map(({ size, ...field }) => (
        <Grid item xs={6} key={field.name}>
          <InputByType disabled={disabled} form={form} {...field} />
        </Grid>
      ))}
    </Grid>
  )
}

export default AvailableResourcesBlock
