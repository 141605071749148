import React from "react"
import { MultistepForm } from "kui-crm"
import { observer } from "mobx-react"
import { Loading } from "kui-complex"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import renderRoomsImagesSteps from "../../common/InspectionImagesStep/imagesSteps/renderSteps"
import FullInfoStep from "./FullTransferInfoStep"
import TransferTypeStep from "./TransferTypeStep"
import { InspectionFormProps } from "../../types"
import renderPartialMeterSteps from "../../common/PartialMeterInfoStep/renderPartialMeterSteps"
import InspectionFillingScreen from "../../common/InspectionFillingScreen"
import ContractFileStep from "../../common/ContractFileStep"

const TransferInspectionForm = (props: InspectionFormProps) => {
  const { inspectionsStore, apartmentInfo, metersStore, fillingStore } =
    useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const shouldUploadContract =
    !apartmentInfo?.actualServiceContractId &&
    formStore.fields?.transferType === "maroom_tenant"

  return (
    <MultistepForm stepShift={1} form={formStore}>
      <TransferTypeStep />
      {shouldUploadContract && <ContractFileStep />}
      {metersStore.loader.isLoading && <Loading height="200px" />}
      {renderPartialMeterSteps(formStore.fields?.meters || [])}
      {fillingStore.loader.isLoading && <Loading height="200px" />}
      <InspectionFillingScreen />
      {renderRoomsImagesSteps("transfer", apartmentInfo)}
      <FullInfoStep {...props} />
    </MultistepForm>
  )
}

export default observer(TransferInspectionForm)
