import React from "react"
import { useForm } from "react-hook-form"
import { InputFileWithVisibility } from "kui-crm"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import FormWrapper from "../../../../components/common/FormWrapper"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import { ContractFileStepFields } from "./types"
import ContractFileStepSchema from "./schema"

const ContractFileStep = () => {
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<ContractFileStepFields>({
    values: {
      contractFile: formStore.fields?.contractFile,
    },
    // @ts-ignore
    resolver: yupResolver(ContractFileStepSchema),
  })

  const handleSubmit = (data: ContractFileStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      title="Загрузите подписанный договор"
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <InputFileWithVisibility
        label="Договор"
        name="contractFile"
        uploadLabel="Загрузить"
        form={form}
        withEdit={false}
      />
    </FormWrapper>
  )
}

export default observer(ContractFileStep)
