import { ContractStatuses } from "kui-crm"

const isContractActive = (status?: ContractStatuses | null) =>
  !(
    !status ||
    status === "Closed" ||
    status === "Archived" ||
    status === "Pending"
  )

export default isContractActive
