import { makeAutoObservable, runInAction } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import {
  apartmentFields,
  ApartmentModel,
  InspectionTypes,
  LiteUserModel,
} from "kui-crm"
import { FillingListStore, inspectionsRequests } from "kui-crm_actions"
import ApartmentAgent from "../../agent/ApartmentAgent"
import { ApartmentParams } from "../../types/store/apartments"
import ApartmentMetersStore from "./ApartmentMetersStore"
import InspectionsStore from "./InspectionsStore"
import isContractActive from "../../utils/isContractActive"

class ApartmentStore {
  apartmentInfo: ApartmentParams | null

  availableInspectionTypes: InspectionTypes[]

  inspectionsStore: InspectionsStore

  metersStore: ApartmentMetersStore

  fillingStore: FillingListStore

  loader: Loader

  constructor() {
    this.apartmentInfo = null
    this.inspectionsStore = new InspectionsStore(this)
    this.availableInspectionTypes = []
    this.metersStore = new ApartmentMetersStore(this)
    this.fillingStore = new FillingListStore()
    this.loader = new Loader(true)
    makeAutoObservable(this)
  }

  fetchApartment = async (id: number) => {
    this.loader.startLoading()

    const response = await to(ApartmentAgent.getById(id))
    await this.fetchAvailableInspectionTypes(id)

    resHandler(response, this.loader, this.updateApartmentInfo)
  }

  fetchAvailableInspectionTypes = async (id: number) => {
    const inspectionTypes = await inspectionsRequests.getInspectionTypes(id)
    runInAction(() => {
      if (inspectionTypes) {
        this.availableInspectionTypes = inspectionTypes
      } else {
        this.loader.setError("getting inspection types")
      }
    })
  }

  updateApartmentInfo = (res: ApartmentModel) => {
    this.apartmentInfo = {
      id: res.id,
      folderNumber: res.folder_number,
      imageUrl:
        res.main_image?.image_medium_url || res.main_image?.image_url || "",
      landlord: ApartmentStore.getClientParams(res.owner),
      tenant: ApartmentStore.getClientParams(res.renter),
      communalServicesAccount: res.financial_personal_account || "",
      company: res.administrative_company
        ? {
            ...res.administrative_company,
            operatingAccount: res.payer_code,
          }
        : null,
      actualRentalContractId:
        res.rental_contracts.find((contract) =>
          isContractActive(contract.status),
        )?.id || null,
      actualServiceContractId:
        res.service_contracts.find((contract) =>
          isContractActive(contract.status),
        )?.id || null,
      payerCode: res.payer_code,
      additionalTerms: "",
      countryOfResidence: null,
      taxesType: null,
      contracts: res.rental_contracts.map((contract) => ({
        id: contract.id,
        number: contract.number,
      })),
      rentalContractStatus: res.rental_contracts?.[0].status || null,
      hasServiceContract: !!res.owner,
      ...apartmentFields.getApartmentDescriptionInfo(res),
    }
  }

  resetApartmentPage = () => {
    this.apartmentInfo = null
    this.metersStore = new ApartmentMetersStore(this)
    this.loader = new Loader(true)
    this.inspectionsStore = new InspectionsStore(this)
  }

  get apartmentId() {
    return this.apartmentInfo?.id
  }

  static getClientParams = (client: LiteUserModel | null) => {
    if (!client) return null

    return {
      name: client.full_name,
      phone: client.phone?.replace(/\D/g, ""),
    }
  }
}

export default ApartmentStore
