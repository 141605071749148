import { makeAutoObservable } from "mobx"
import { Loader, resHandler } from "kui-utils"
import {
  InitialInspectionModelFields,
  InspectionFeedbackParams,
  InspectionFillingModel,
  InspectionModel,
  InspectionTypes,
} from "kui-crm"
import to from "await-to-js"
import {
  apartmentFields,
  AvailableResourcesParams,
  CommonInspectionFormFields,
} from "kui-crm_actions"
import { DateTime } from "luxon"
import {
  InitialInspectionFormFields,
  InspectionFields,
  RegularInspectionFormFields,
  TransferInspectionFormFields,
  InventoryInspectionFormFields,
} from "../../../types/store/inspections"
import ApartmentInspectionAgent from "../../../agent/ApartmentInspectionAgent"
import InspectionsStore from "../../../store/apartment/InspectionsStore"

class InspectionStore {
  id: number | null

  apartmentId: number | null

  type: InspectionTypes | null

  date: DateTime | null

  fields: InspectionFields | null

  loader: Loader

  actionLoader: Loader

  tenantFeedback: InspectionFeedbackParams | null

  landlordFeedback: InspectionFeedbackParams | null

  constructor() {
    this.id = null
    this.apartmentId = null
    this.type = null
    this.date = null
    this.fields = null
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.tenantFeedback = null
    this.landlordFeedback = null
    makeAutoObservable(this)
  }

  fetchInspection = async (apartmentId: number, id: number) => {
    if (apartmentId) {
      this.setApartmentId(apartmentId)

      this.loader.startLoading("inspection")

      const response = await to<InspectionModel>(
        ApartmentInspectionAgent.getById(apartmentId, id),
      )

      resHandler(response, this.loader, this.updateInspectionFields)
    }
  }

  resignInspection = async () => {
    const inspectionId = this.id

    if (this.apartmentId && inspectionId) {
      this.actionLoader.startLoading()

      const response = await to(
        ApartmentInspectionAgent.resign(this.apartmentId, inspectionId),
      )

      resHandler(response, this.actionLoader, this.updateFeedback)
    }
  }

  setApartmentId = (apartmentId: number) => {
    this.apartmentId = apartmentId
  }

  updateFeedback = () => {
    this.tenantFeedback = this.tenantFeedback
      ? {
          ...this.tenantFeedback,
          status: "notSigned",
        }
      : null
  }

  updateInspectionFields = (inspection: InspectionModel) => {
    this.id = inspection.id
    this.type = inspection.inspection_type
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.fields = InspectionStore.getInspectionFields(inspection)
    this.tenantFeedback = InspectionsStore.getInspectionFeedbackParams(
      inspection.renter_feedback,
    )
    this.landlordFeedback = InspectionsStore.getInspectionFeedbackParams(
      inspection.owner_feedback,
    )
  }

  resetInspection = () => {
    this.id = null
    this.apartmentId = null
    this.type = null
    this.date = null
    this.fields = null
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.tenantFeedback = null
    this.landlordFeedback = null
  }

  static getInspectionFields = (inspection: InspectionModel) => {
    switch (inspection.inspection_type) {
      case "initial":
        return InspectionStore.getInitialFields(inspection)
      case "inventory":
        return InspectionStore.getInventoryFields(inspection)
      case "transfer":
        return InspectionStore.getTransferFields(inspection)
      case "regular":
        return InspectionStore.getRegularFields(inspection)
      default:
        return null
    }
  }

  static getCommonFields = (
    inspection: InspectionModel,
  ): CommonInspectionFormFields => ({
    date: DateTime.fromISO(inspection.date),
    type: inspection.inspection_type,
    dropboxLink: inspection.dropbox_link,
  })

  static getRoomsImagesParams = (inspection: InspectionModel) => ({
    roomsImages: apartmentFields.getObjectRoomsImages(inspection.photo_groups),
  })

  static getFillingGroupsParams = (
    fillingList: InspectionFillingModel[],
    withChecking?: boolean,
  ) => ({
    fillingList:
      fillingList?.map((filling) =>
        apartmentFields.getInspectionFillingParams(filling, withChecking),
      ) || [],
  })

  static getInitialFields = (
    inspection: InspectionModel,
  ): InitialInspectionFormFields => ({
    metroStations: apartmentFields.getMetroStationsParams(
      inspection.initial!.metro,
    ),
    ...InspectionStore.getCommonFields(inspection),
    ...apartmentFields.getApartmentHouseInfo(inspection.initial!),
    ...apartmentFields.getApartmentMainInfo(inspection.initial!),
    ...apartmentFields.getApartmentSecurityInfo(inspection.initial!),
    ...apartmentFields.getApartmentDetailInfo(inspection.initial!),
    ...InspectionStore.getRoomsImagesParams(inspection),
    availableResources: InspectionStore.getAvailableResourcesParams(
      inspection.initial!,
    ),
  })

  static getInventoryFields = (
    inspection: InspectionModel,
  ): InventoryInspectionFormFields => ({
    ...InspectionStore.getCommonFields(inspection),
    ...apartmentFields.getApartmentDescriptionWithMetro(inspection.inventory!),
    ...apartmentFields.getInspectionPaymentInfo(inspection.inventory!),
    ...apartmentFields.getInventoryMetersInfo(inspection.inventory!),
    ...InspectionStore.getRoomsImagesParams(inspection),
    ...InspectionStore.getFillingGroupsParams(inspection.inventory!.filling),
  })

  static getTransferFields = (
    inspection: InspectionModel,
  ): TransferInspectionFormFields => ({
    transferType: inspection.transfer!.transfer_type,
    ...InspectionStore.getCommonFields(inspection),
    ...apartmentFields.getApartmentDescriptionWithMetro(inspection.transfer!),
    ...apartmentFields.getTransferMetersInfo(inspection.transfer!.meters),
    ...InspectionStore.getRoomsImagesParams(inspection),
    ...InspectionStore.getFillingGroupsParams(
      inspection.transfer!.filling,
      true,
    ),
  })

  static getRegularFields = (
    inspection: InspectionModel,
  ): RegularInspectionFormFields => ({
    ...InspectionStore.getCommonFields(inspection),
    ...apartmentFields.getTransferMetersInfo(inspection.regular!.meters),
    ...InspectionStore.getRoomsImagesParams(inspection),
    ...InspectionStore.getFillingGroupsParams(
      inspection.regular!.filling,
      true,
    ),
  })

  static getAvailableResourcesParams = (
    inspection: InitialInspectionModelFields,
  ): AvailableResourcesParams => ({
    water: !!inspection.used_resources?.water,
    electricity: !!inspection.used_resources?.electricity,
    gas: !!inspection.used_resources?.gas,
    heating: !!inspection.used_resources?.heating,
  })
}

export default InspectionStore
