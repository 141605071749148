import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"

const ContractFileStepSchema = yup.object().shape({
  contractFile: yup.object({
    file: yup.mixed().required(defaultRequiredMessage),
  }),
})

export default ContractFileStepSchema
